html {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen"; */
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
    -webkit-font-smoothing: subpixel-antialiased;
    /*控制字体渲染时的平滑效果*/
    /*-webkit-user-select:none;      !*取消文字选中*!*/
    background: #fff !important;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-text-size-adjust: 100%;
    /* IOS 横屏时默认字体为140%；旋转屏幕时，字体大小不变 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* 修正 IOS 和 Android 可点击元素的高亮颜色 */
    -webkit-tap-highlight-color: transparent;
    /* i.e. Nexus5/Chrome and Kindle Fire HD 7'' */
    -webkit-touch-callout: none;
    /* callout是指当触摸并按住一个元素的时候出现的提示*/
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "Helvetica", sans-serif;
    /* font-weight: 400; */
}

/*@font-face {*/
/*font-family:'pingfang';*/
/*src:url('./assets/images/FZS3JW.TTF');*/
/*}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    -webkit-touch-callout: none;
    /* callout是指当触摸并按住一个元素的时候出现的提示*/
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[hidden] {
    display: none !important;
}

[data-flex] {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

[data-flex-between] {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

[data-flex-around] {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

img {
    -webkit-touch-callout: none;
    /* callout是指当触摸并按住一个元素的时候出现的提示*/
}

input,
textarea {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@keyframes buttonEffect {
    to {
        opacity: .05;
        top: -0.2rem;
        left: -0.2rem;
        bottom: -0.2rem;
        right: -0.2rem;
        background: orange;
        border-width: 0.018rem
    }
}

.decoration-effect:after {
    content: "";
    position: absolute;
    top: -0.053rem;
    left: -0.053rem;
    opacity: 0.6;
    bottom: -0.053rem;
    right: -0.053rem;
    border-radius: inherit;
    border: 0 solid rgba(255, 156, 96, 1);
    -webkit-animation: buttonEffect .4s;
    animation: buttonEffect .4s;
    display: block;
}

@font-face {
    font-family: 'iconfont';
    src: url('./assets/iconfont/iconfont.eot');
    src: url('./assets/iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
        url('./assets/iconfont/iconfont.woff') format('woff'),
        url('./assets/iconfont/iconfont.ttf') format('truetype'),
        url('./assets/iconfont/iconfont.svg#iconfont') format('svg');
}

.iconfont {
    font-style: normal;
    font-family: "iconfont" !important;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
}

[data-innerhtml-style] img {
    max-width: 100% !important;
    display: block !important;
    height: auto !important;
    margin: 0.2rem auto !important;
    border-radius: 0.12rem !important;
    border: none !important;
    object-fit: contain;
}

[data-innerhtml-style] p:first-child img {
    margin-top: 0.6rem !important;
}

[data-innerhtml-style] p {
    /* text-align: justify!important; */
    text-align: justify;
}

[data-innerhtml-style1] img {
    max-width: 100% !important;
    display: block !important;
    height: auto !important;
    margin: 0.2rem auto !important;
    border-radius: 0.12rem !important;
    border: none !important;
    object-fit: contain;
}

[data-innerhtml-style1] p:first-child img {
    margin-top: 0.6rem !important;
}

[data-innerhtml-style1] p {
    text-align: justify !important;
}

body.ModalNoScroll {
    position: fixed;
    width: 100%;
}

body iframe {
    display: none;
}

.wx-image-viewer {
    z-index: 10000000 !important;
}

/* 
    全局允许滑动 解决[Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive. See <URL>
*/
* {
    touch-action: pan-y;
}

/* 
    支付确认页 modal样式 
    haogupiao\src\views\confirmPay\index.jsx
*/
.confirm-pay-modal .am-modal-content {
    padding: 0 !important;
    background-color: transparent !important;
}

.confirm-pay-modal .am-modal-content .am-modal-body {
    padding: 0 !important;
    background-color: transparent !important;
}

.confirm-pay-modal.am-modal-transparent {
    width: 7.2rem;
}

.confirm-pay-modal .modal-box {
    width: 7.2rem;
    margin: 0 auto;
    position: relative;
}

.confirm-pay-modal .modal-box .img-box{
    width: 100%;
    height: 9.2rem;
    background: url('./assets/images/branch/gzh_bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirm-pay-modal .modal-box .img-box.img-box2{
    height: 6.2rem;
    background: url('./assets/images/branch/gzh_bg2.png') no-repeat;
    background-size: 100% 100%;
}
.confirm-pay-modal .modal-box .img-box.img-box-code{
    height: 12rem;
    background: url('./assets/images/branch/gzh_bg3.jpg') no-repeat;
    background-size: 100% 100%;
}
.confirm-pay-modal .modal-box .img-box.img-box-code img{
    width: 4rem;
    height: 4rem;
    margin-top: 1rem;
}

.confirm-pay-modal .modal-box .img-box img{
    width: 2.2rem;
    height: 2.2rem;
    margin-top: 2.2rem;
}


.confirm-pay-modal .modal-box img {
    width: 100%;
}
.confirm-pay-modal .modal-box img.close {
    width: 20px;
    display: block;
    margin: 44px auto 0;
}

.confirm-pay-modal .modal-box .modal-inner {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-pay-modal .modal-box .modal-inner button {
    background: linear-gradient(0deg, #FF9A2E, #FFD928);
    box-shadow: 0px 1px 1px 0px #FFF4E1;
    border-radius: 0.133rem;
    width: 4.133rem;
    height: 1.093rem;
    border: 0;
    outline: 0;
    font-size: 0.427rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 0.42rem;
}

.confirm-pay-modal .modal-box .modal-inner span {
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #D94315;
    font-size: 0.4rem;
}

.confirm-pay-modal .modal-box .modal-inner p {
    font-size: 0.56rem;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #510B1B;
    margin-bottom: 0.693rem;
}


/* 
    <-- end -->
*/

/* 
    企微备注 modal样式 
    haogupiao\src\views\wecom\customerinfo\index.jsx
*/
.wx-remark-modal{
    width: 100% !important;
}
.wx-remark-modal .am-modal-content{
    padding: 0;
}
.wx-remark-modal .am-modal-header{
    padding: 0.38rem;
}
.wx-remark-modal  .am-modal-header .am-modal-title{
    font-size: 0.36rem;
    font-weight: bold;
    color: #252525;
}
.wx-remark-modal .am-modal-body{
    padding: 0.1rem 0rem 1.4rem !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.wx-remark-modal .remark-container{
    flex: 1;
    overflow: auto;
    padding: 0 0.66rem;
}
.wx-remark-modal .remark-container .am-list-item{
    padding-left: 0;
    border: 1px solid #E5E5E5;
    margin-bottom: 0.5rem;
}
.wx-remark-modal .am-list-item .am-textarea-control{
    padding: 0.5rem;
}
.wx-remark-modal .am-textarea-control textarea {
    font-size: 0.3rem;
}
.wx-remark-modal .remark-btns{
    height: 0.8rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}
.wx-remark-modal .remark-btns .remark-cancel{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.8rem;
    border: 1px solid #386FF2;
    border-radius: 0.06rem;
    font-size: 0.3rem;
    color: #386FF2;
    cursor:pointer;
}
.wx-remark-modal .remark-btns .remark-confirm{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.8rem;
    background: #386FF2;
    border-radius: 0.06rem;
    font-size: 0.3rem;
    color: #FFFFFF;
    cursor:pointer;
}

/* 
    <-- end -->
*/

/* 
    企微持仓信息股票代码\名称 modal样式 
    haogupiao\src\views\wecom\holdingInfo\index.jsx
*/
.wx-modal-popup{
    background: #ffffff;
    border-radius: 0.28rem 0.28rem 0rem 0rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.wx-modal-popup .am-modal-content{
    border-radius: 0.28rem 0.28rem 0rem 0rem;
    overflow: hidden;
}

.wx-modal-popup .am-modal-body{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.wx-modal-popup .am-modal-body .am-list{
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.wx-modal-popup .am-list .am-list-body::before {
    background-color: #f5f5f5;
}

.wx-modal-popup .am-list .am-list-body::after{
    width: calc(100% - 1.1rem);
    left: 0.55rem;
    background-color: #f5f5f5;
}

.wx-modal-popup .am-list .am-list-item{
    padding: 0 0.55rem;
}

.wx-modal-popup .am-list .am-list-item .am-list-line {
    padding: 0;
}

.wx-modal-popup .am-list .am-list-item .am-list-line::after {
    background-color: #f5f5f5;
}

.wx-modal-popup .am-list .am-list-item .am-list-line .am-list-content{
    padding: 0.34rem 0;
    font-size: 0.28rem;
    font-weight: 400;
    color: #7d8b97;
    line-height: 0.42rem;
}

.wx-modal-popup .am-list .am-list-content .am-list-brief {
    display: none;
}

.wx-modal-popup .am-list .am-list-extra .am-radio-inner {
    right: 0;
}

.wx-modal-popup .am-list .am-list-extra .am-radio-inner::after {
    width: 8px;
    border-width: 0 2px 2px 0;
}

.wx-modal-popup .search {
    height: 1.48rem;
    background-color: #fff;
    padding: 0.3rem 0.6rem;
    border: 1px solid #f5f5f5;
    border-bottom: none;
}    

.wx-modal-popup .modalPopupHeader {
    display: flex;
    justify-content: flex-end;
    padding: 0.26rem 0.8rem;
}

.wx-modal-popup .modalPopupHeaderBewtten{
    justify-content: space-between !important;
}

.wx-modal-popup .modalPopupHeader .popupHeaderItem{
    font-size: 0.34rem;
    font-weight: 400;
    color: #a3a9b7;
    cursor: pointer;
}

.wx-modal-popup .modalPopupHeader .popupHeaderR {
    color: #3775c6;
}

.wx-modal-popup .search .am-search {
    height: 100%;
    padding: 0;
    background: #f7f9fa;
    border-radius: 7px;
}    

.wx-modal-popup .am-search .am-search-input {
    background: #f6f9fa;
}

.wx-modal-popup .am-search .am-search-input .am-search-value::placeholder {
    font-size: 0.34rem;
}

.wx-modal-popup .am-search .am-search-cancel {
    padding-right: 0.6rem;
    font-size: 0.38rem;
    color: #3775c6;
}

.wx-modal-popup .modal-toolbar{
    padding: 0.4rem 0.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.34rem;
    color: #333;
}

.wx-modal-popup .modal-toolbar .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.wx-modal-popup .modal-toolbar .operation img{
    width: 0.36rem;
    height: 0.36rem;
    margin-right: 0.2rem;
}

.wx-modal-popup .checked-box{
    padding: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.32rem;
}

.wx-modal-popup .checked-item{
    width: 48%;
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    cursor: pointer;
    font-size: 0.28rem;
}

.wx-modal-popup .checked-item:nth-child(2n){
    margin-left: 4%;
}

.wx-modal-popup .checked-item span{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}

.wx-modal-popup .checked-item img{
    margin-right: 0.3rem;
    width: 0.36rem;
    height: 0.36rem;
}

.wx-modal-popup .my-accordion{
    overflow: auto;
    text-align: left;
}

.wx-modal-popup .my-accordion .am-accordion-item .am-accordion-header{
    height: 36px;
    line-height: 36px;
    color: #333;
    font-size: 0.3rem;
}

.wx-modal-popup .my-accordion .am-accordion-item .am-accordion-header .arrow{
    top: 12px;
    width: 10px;
    height: 10px;
}

.wx-modal-popup .my-accordion-child {
    border-top: none;
    padding: 0 0 0 0.4rem;
}

.wx-modal-popup .my-accordion-child .am-accordion-content-box{
    padding: 0.2rem 0.4rem 0 0;
}

.wx-modal-popup .my-accordion-child .am-accordion-item .am-accordion-header{
    font-size: 0.28rem;
}

.wx-modal-popup .my-accordion-child .am-accordion-content-box .operation {
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wx-modal-popup .my-accordion-child .am-accordion-content-box .operation img{
    width: 0.36rem;
    height: 0.36rem;
    margin-right: 0.2rem;
}

.wx-modal-popup .my-accordion-child .am-accordion-item:last-child .am-accordion-content .am-accordion-content-box{
    border-bottom: none;
}

/* 
    <-- end -->
*/

.MuiTooltip-tooltip{
    font-size: 0.24rem !important;
}

.fade img{
    max-width: 78% !important;
}


/* 
    企微应用工作台-搜索持仓客户信息弹窗 
    haogupiao\src\views\wecom\workbench\components\PositionHolder\index.jsx
*/

.customer-info-modal .content{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.customer-info-modal .content .basic-info{
    display:flex;
    align-items: center;
}

.customer-info-modal .basic-info .head-picture{
    width: 40px;
    height: 40px;
    background: #B8B8B8;
    border-radius: 4px;
}

.customer-info-modal .basic-info .head-picture img{
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.customer-info-modal .basic-info  .customer-name{
    margin-left: 20px;
    font-size: 14px;
    color: #000000;
}

.customer-info-modal .basic-info  .is-suggest{
    display: flex;
    align-items: center;
    margin-left: 10px
}

.customer-info-modal .basic-info  .is-suggest img{
    width: 30px;
    height: 30px;
}

.customer-info-modal .content .main{
    margin-top: 20px;
}
.customer-info-modal .main .ant-row .ant-col {
    margin-top: 10px;
}
/* 
    <--企微应用工作台-搜索持仓客户信息弹窗  end -->
*/


/* 
    签约用户客户风险测评 modal样式 
    haogupiao\src\views\diagnosisSecondary\index.jsx
*/
.qa-evaluation-modal .am-modal-header .am-modal-title{
    font-weight: 500;
    color: #272734;
}

.qa-evaluation-modal .evaluation-prompt{
    text-align: left;
    font-size: 0.37rem;
    font-weight: 400;
    color: #272734;
    line-height: 0.6rem;
}
.qa-evaluation-modal .evaluation-prompt .tips-text span{
    color: #2F6BDD;
}

.qa-evaluation-modal .evaluation-prompt .am-button{
    margin: 0.4rem auto 0;
    width: 4.92rem;
    height: 1.01rem;
    background: #2F6BDD;
    border-radius: 0.13rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qa-evaluation-modal .am-modal-footer .am-modal-button{
    color: #2F6BDD;
}

/* 
    签约用户客户风险测评 modal样式 end
*/


.pay-btn1{
    background: url('./assets/images/wxpay/bg1.png') no-repeat;
    background-size: 100% 100%;
}
.pay-btn2{
    background: url('./assets/images/wxpay/bg2.png') no-repeat;
    background-size: 100% 100%;
}
.pay-btn3{
    background: url('./assets/images/wxpay/bg3.png') no-repeat;
    background-size: 100% 100%;
}